/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import '~/styles/global.css';

// Add fonts installed via NPM
require('typeface-poppins');
require('typeface-anonymous-pro');
